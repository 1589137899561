export function is_string(obj) {
  return typeof obj === 'string' || obj instanceof String;
}
export function is_empty_obj(obj) {
  return obj // 👈 null and undefined check
  && Object.keys(obj).length === 0 && obj.constructor === Object;
}
export function is_hex(inputString) {
  var re = /^[0-9A-Fa-f]+$/;
  return re.test(inputString);
}
export function ms_to_time(ms) {
  if (ms < 1000) return ms + "ms";
  if (ms < 1000 * 60) return (ms / 1000).toFixed(2) + "s";
  if (ms < 1000 * 60 * 60) return (ms / 1000 / 60).toFixed(2) + "mn";
  if (ms < 1000 * 60 * 60 * 24) return (ms / 1000 / 60 / 60).toFixed(2) + "hours";else return (ms / 1000 / 60 / 60 / 24).toFixed(2) + "days";
}
export function get_time() {
  var today = new Date();
  var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + ' ' + time;
  return dateTime;
}
export function sleep_await(ms) {
  // use with await, only for tests & debug as it will slow everything
  return new Promise(function (resolve) {
    setTimeout(resolve, ms);
  });
}
export function deepcopy(obj) {
  var stringifyed = JSON.stringify(obj);
  if (!stringifyed) return "undefined"; // this happens with 2 jigs looped one into the other for example

  return JSON.parse(stringifyed);
}
export function with2Decimals(num) {
  return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
}
export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}